
$brand-primary: #ea222e;

$border-radius: 2px;
$border-radius-lg: .25rem;

$font-size-lg: 1.1rem;

//== Buttons

$btn-primary-bg: $brand-primary;
$btn-primary-border: transparent;
$btn-border-radius-lg: $border-radius-lg;

$btn-padding-x: 1.5rem;
$btn-padding-y: 1rem;
$btn-padding-x-lg: 2rem;
$btn-padding-y-lg: 1.25rem;

//== Navbar

$navbar-border-radius: 0;

$navbar-dark-color: white;
$navbar-dark-hover-color: white;
$navbar-dark-active-color: white;
$navbar-dark-disabled-color: white;

//== Jumbotron

$jumbotron-color: white;
