// TODO: Streamline

/* Leaflet and Pelias overrides */
.leaflet-bar a.leaflet-pelias-search-icon {
  background-image: url("../images/search.png");
  width: 40px;
  background-size: 24px;
}
.leaflet-pelias-control.leaflet-pelias-expanded .leaflet-pelias-search-icon {
  background-image: url("../images/search.png");
}
.leaflet-left .leaflet-pelias-control {
  margin-left: -150px;
}
.leaflet-top .leaflet-pelias-control {
  margin-top: 0;
}

.leaflet-pelias-control {
  position: fixed;
  left: 50%;
  top: 82px;
}

@media only screen and (max-width: 650px) {
  .leaflet-pelias-control {
    top: 82px;
  }
}
@media only screen and (orientation: landscape) {
  .leaflet-touch .leaflet-pelias-control {
    top: 82px;
  }
}

.leaflet-pelias-control.leaflet-pelias-expanded {
  width: 300px; /* note - css tacks on an additional 30px w/o border-box */
}

@media only screen and (max-width: 650px) {
  .leaflet-pelias-control.leaflet-pelias-expanded {
    width: calc(100% - 60px)
  }

  .leaflet-left .leaflet-pelias-control {
    margin-left: calc((100% - 100px) / -2);
  }
}

.leaflet-touch .leaflet-pelias-control.leaflet-pelias-expanded {
  height: 48px; /* include height of border on mobile */
}

@media only screen and (max-width: 650px) {
  .leaflet-touch .leaflet-pelias-control.leaflet-pelias-expanded {
    left: 10px;
    margin-left: 0;
    width: calc(100% - 24px);
  }
}

// override vertical-align: middle applied to all imgs
img.leaflet-pelias-layer-icon {
  vertical-align: top;
}

/* Hide zoom controls on mobile */
@media only screen and (max-width: 650px) {
  .leaflet-touch .leaflet-control-zoom {
    display: none;
  }
}

@media only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
  .leaflet-pelias-search-icon {
    background-image: url("../images/search@2x.png");
  }
  .leaflet-pelias-control.leaflet-pelias-expanded .leaflet-pelias-search-icon {
    background-image: url("../images/search@2x.png");
  }
}

.leaflet-pelias-input {
  padding-left: 36px;
}

// ATTRIBUTION
.leaflet-control-attribution {
  background-color: #58595b;
}
.leaflet-bottom.leaflet-right {
  width: 100%;
}
.leaflet-control-attribution.leaflet-control {
  background: #58595b;
  color: white;
  width: 100%;
  font-size: 10px;
  float: none;
  text-align: right;
  padding: 3px 6px;
}

.leaflet-control-attribution.leaflet-control a {
  color: white;
}
