#map {
  position: absolute;
  top: 72px;
  left: 0;
  width: 100%;
  height: calc(100% - 72px);
}

//
// Borrowed the Mapzen 'Locate me' button
// -----------------------------------------------

.mz-geolocator {
  top: 122px !important;
}

.leaflet-touch .mz-geolocator {
  top: 60px !important;
  left: auto;
  right: 10px;
}

.leaflet-touch .mz-geolocator-button {
  /* mimic leaflet-bar */
  border: 2px solid rgba(0,0,0,0.2);
  background-clip: padding-box;
  box-shadow: none;
  width: 48px;
  height: 48px;
  border-radius: 6px; /* ??? */
}

.leaflet-touch .mz-geolocator-icon {
  background-size: 60%;
}

#pin {
  position: absolute;
  width: 176px;
  height: 64px;
  top: 50%;
  left: 50%;
  margin-top: -64px;
  margin-left: -88px;
  border: 3px solid rgba(237, 29, 36, 1);
  border-radius: 6px;
  background-color: rgba(244, 244, 244, 0.99);
  z-index: 1000;
}

#pin::before {
  content: '';
  display: block;
  position: absolute;
  bottom: -48px;
  left: 50%;
  margin-left: -24px;
  border: 24px solid transparent;
  border-top-color: rgba(237, 29, 36, 1);
  z-index: 1000;
  pointer-events: none;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

#emojis {
  text-align: center;
  font-size: 3em;
  line-height: 58px;
}

img.emojione {
  width: 32px;
  height: 32px;
}

img.emojione:not(:last-of-type) {
  margin-right: 16px;
}
