@charset 'utf-8';

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,700,300italic');

@import 'bootstrap-variables';
@import '../../node_modules/bootstrap/scss/bootstrap';

@import 'navbar';
@import 'buttons';

@import 'map'; // Map page base
@import '../../node_modules/leaflet.locatecontrol/src/L.Control.Locate';
@import 'leaflet-overrides';

body.main {
  overflow-x: hidden;
}

body {
  color: #0a3049;
  font-family: 'Source Sans Pro', Roboto, Arial, sans-serif;
  font-weight: 300;
}

.jumbotron {
  position: relative;
  padding-top: 140px;
  padding-bottom: 80px;
  margin-bottom: 0;
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  background-repeat: no-repeat;
  background-image: url('../images/slideshow01.jpg');
  background-size: cover;
  background-position: center center;
  border-radius: 0;
  color: white;

  @include media-breakpoint-up(md) {
    padding: 70px 0;
    padding-top: 200px;
    padding-bottom: 120px;
  }

  .lead {
    @include media-breakpoint-up(md) {
      width: 85%;
      font-size: 2em;
      margin: 0 auto;
    }
  }

  .container {
    max-width: 100%;

    @include media-breakpoint-up(md) {
      max-width: 85%;
    }
  }
}

body.login {
  .jumbotron {
    background-image: url('../images/slideshow02.jpg');
  }
  .jumbotron .container {
    padding: 40px;
    background-color: rgba(0,0,0,0.5);
  }
  .subscribe {
    margin-top: 40px;
  }
}

.fineprint {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 10px !important;
  opacity: 0.8;
}

.jumbotron-button {
  margin-top: 40px;

  @include media-breakpoint-up(md) {
    margin-top: 80px;
  }
}

.jumbotron-photo-credit {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: white;
  text-align: right;
  font-size: 9px;
  font-weight: 300;

  a,
  a:active,
  a:visited {
    color: white;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .cc {
    margin-left: 0.33em;
    opacity: 0.6;
  }
}

.explore-map-button {
  display: block;
  padding: 20px 32px;
  border-radius: 3px;
  width: 100%;
  color: white;
  font-weight: 300;
  font-size: 16px;
  background-color: $brand-primary;
  box-shadow: 0 2px 0 #c4101c;

  @include media-breakpoint-up(md) {
    display: inline-block;
    padding: 26px 32px;
    width: auto;
    min-width: 180px;
  }

  &:hover,
  &:active,
  &:visited {
    color: white;
    text-decoration: none;
  }
}

.featurette {
  padding: 50px 0;
  color: #4d4d4d;
  background-color: #f8f8f8;
  text-align: center;

  hr {
    margin: 40px auto;
  }

  h2 {
    font-size: 28px;
    font-weight: 300;
    line-height: 1.1em;
    margin-bottom: 30px;
  }

  .lead {
    font-weight: 200;
    font-size: 20px;
  }

  .boom {
    font-weight: 200;
    font-size: 26px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.intro {
  margin-top: 30px;
  margin-bottom: 30px;
}

.triptych {
  margin-top: 20px;
  margin-bottom: 40px;

  .emojione {
    width: 128px;
    height: 128px;
  }

  h3 {
    font-weight: 300;
    margin: 20px auto;
  }

  p {
    font-weight: 200;
    line-height: 1.5;
  }
}

.triangles img {
  width: 16px;
  height: 16px;
}

.x-mark {
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.featurette-alt {
  background-color: white;
}

.package-hero {
  //background-image: url(/img/package.jpg);
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  //min-height: 300px;
  //height: 600px;
  padding: 0;

  p {
    text-align: left;
    color: white;
    font-weight: 300;
    text-shadow: 0 1px 0 rgba(0,0,0,0.2);
  }

  .overlay {
    max-width: 100%;
    background-color: rgba(0,0,0,0.65);
    padding: 40px;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      max-width: 400px;
      padding-top: 160px;
      display: flex;
    }
  }

  @include media-breakpoint-up(md) {
    .overlay-text {
      align-self: flex-end;
    }
    .btn-lg {
      margin: auto;
    }
  }
}

.quote {
  padding-bottom: 20px;
  margin-bottom: 0;
}

.keep-together {
  white-space: nowrap;
}

.quote-mark {
  color: #e6313e;
  font-size: 2em;
  font-family: serif;
  line-height: 1em;
  vertical-align: sub;
}

.quote-attribution {
  margin-top: 0;
  font-size: 18px;
  font-weight: 200;
}

footer {
  background-color: #4d4d4d;
  min-height: 300px;
  color: #eee;
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 0.85rem;

  h4 {
    text-transform: uppercase;
    margin-bottom: 1em;
    font-size: 1rem;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 30px;
  }

  li {
    margin-bottom: 0.2em;
  }

  li a {
    color: #eee;
    font-weight: 200;
  }

  a:hover,
  a:active,
  a:visited {
    color: #eee;
  }

  .twitter-timeline {
    margin: 0 auto;
  }
}

.copyright {
  background-color: #4d4d4d;
  border-top: 1px solid #777;
  padding: 20px 0;
  color: #aaa;
  font-size: 12px;
}

.subscribe .button {
  padding: 6px 10px;
}
