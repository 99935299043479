
.btn-primary {
  background-color: #c4101c;
  border-color: #c4101c;
  box-shadow: 0 2px 0 #c4101c;
}

.input-group .btn-primary {
  box-shadow: none;
}

.btn-lg {
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn-md {
  margin-top: 20px;
}
