.navbar {
  // Ensure it overlaps map
  z-index: 3000;
}

.navbar-brand {
  img {
    width: 185px;
  }
}

@include media-breakpoint-up(md) {
  .navbar-nav {
    li > a {
      border-bottom: 1px solid transparent;
    }

    li:hover > a {
      border-bottom: 1px solid #0a3049;
    }
  }
}
